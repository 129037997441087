<template>
  <div id="aboutus">
    <Header></Header>
    <div class="breadcrumb-wrap bg-f br-1">
      <div class="overlay bg-black op-9"></div>
      <img :src="shape1" alt="Image" class="br-shape-1" />
      <img :src="shape2" alt="Image" class="br-shape-2" />
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="breadcrumb-title">
              <h2>About Us</h2>
              <ul class="breadcrumb-menu list-style">
                <li>
                  <!--<a href="index-2.html">Home </a>--><router-link
                    to="/"
                    style="color: white"
                    >Home</router-link
                  >
                </li>
                <li>About Us</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="about-wrap style2 ptb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="about-img-wrap">
              <img
                :src="aboutimage1"
                alt="Image"
                class="abouut-shape-1 sm-none"
              />
              <img
                :src="aboutimage2"
                alt="Image"
                class="abouut-shape-2 sm-none"
              />
              <div class="about-bg-4 bg-f"></div>
              <div class="about-bg-5 bg-f"></div>
              <!--<div class="about-bg-6">
                <img :src="aboutbg6" alt="Image" />
              </div>-->
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-content">
              <div class="content-title style2 mb-15">
                <span>
                  <img :src="sectionshape1" alt="Image" />
                  Elegant & Famous
                  <img :src="sectionshape2" alt="Image" />
                </span>
                <h2>Our Restaurant</h2>
              </div>
              <!--<p>
                In our restaurant with people who are important to you, conversations that
                bring you closer to each other and those who enjoy our dishes. Traditional
                dishes with local products of highest quality.
              </p>-->
              <p>
                Enjoy the vast variety of our food including Karahis , Curry
                dishes , BBQ, Vegi dishes , desserts, rice dishes, bread and
                drinks.
              </p>
              <p>
                We believe that excellent food and hospitality are at the center
                of all the big establishments. Our classic dishes are prepared
                with the best ingredients and under the watchful eye of our
                chef, so that your passion and standards of excellence are
                evident in every dish.
              </p>
              <!--<div class="pr-wrap style2">
                <div class="pr-item">
                  <h4>
                    <i class="flaticon-check-box-with-check-sign"></i>This Is All About
                    Caban
                  </h4>
                  <p>
                    Choose food and pay for the order in a couple of clicks online also
                    choose you current location using GPS
                  </p>
                </div>
                <div class="pr-item">
                  <h4>
                    <i class="flaticon-check-box-with-check-sign"></i>This Is Followed by
                    Quality
                  </h4>
                  <p>
                    Follow the status of your order in real time and also track the
                    delivery path until you get it.
                  </p>
                </div>
              </div>-->
              <div>
                <!--<a href="about.html" class="btn style1"
                  >Read More<i class="flaticon-right-arrow-2"></i
                ></a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="contact-wrap style3 bg-f contact-bg-2 ptb-100">
      <div class="container pos-rel">
        <img :src="agent1" alt="Image" class="agent-img md-none" />
        <div class="section-title style6 text-center mb-30">
          <span>
            <img :src="sectionshape1" alt="Image" />
            Contact Us
            <img :src="sectionshape2" alt="Image" />
          </span>
          <h2 class="text-white">
            Not Sure What To Order? <br />
            Download Our App
            <div class="download-app-btn">
              <a href="#"><img :src="googleplaystore" alt="Image" /></a>
              <a href="#"><img :src="appleplaystore" alt="Image" /></a>
            </div>
          </h2>
        </div>
        <div class="row">
          <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
            <a class="contact-num" href="tel:4034522575">403-452-2575</a>
            <div class="contact-btn">
              <!--<a href="tel:4034522575" class="btn style1"
                >Make A Call<i class="flaticon-right-arrow-2"></i
              ></a>-->
              <!--<a href="contact.html" class="btn style3"
                >Contact Us<i class="flaticon-right-arrow-2"></i
              ></a>-->
              <router-link to="/contact" class="btn style3"
                >Contact Us<i class="flaticon-right-arrow-2"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <OurServices></OurServices>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/views/layouts/Header.vue";
import OurServices from "@/views/home/OurServices.vue";
import Footer from "@/views/layouts/Footer.vue";
export default {
  name: "Home",
  data() {
    return {
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      agent1: require("@/assets/img/contact/agent-1.png"),
      googleplaystore: require("@/assets/img/app/google-playstore.png"),
      appleplaystore: require("@/assets/img/app/apple-playstore.png"),
      aboutimage1: require("@/assets/img/about/about-shape-1.png"),
      aboutimage2: require("@/assets/img/about/about-shape-2.png"),
      //aboutbg6: require("@/assets/img/about/about-6.jpg"),
      sectionshape1: require("@/assets/img/secion-shape-1.png"),
      sectionshape2: require("@/assets/img/secion-shape-2.png"),
    };
  },
  components: {
    Header,
    OurServices,
    Footer,
  },
};
</script>
<style scoped>
@media screen and (max-width: 767px) {
  .about-wrap.style2 .about-img-wrap .about-bg-4 {
    display: none;
  }
  .about-wrap.style2 .about-img-wrap {
    width: 100% !important;
    margin: auto;
    display: inline-block;
    padding-right: 15px;
  }
  .about-wrap.style2 .about-img-wrap .about-bg-5 {
    width: 100% !important;
    /* height: 520px; */
    /* border: 6px solid #fff; */
    max-width: 398px;
    /* margin-right: 35px !important; */
    position: relative;
    left: 8px;
  }
}
@media screen and (max-width: 320px) {
  .about-wrap.style2 .about-img-wrap {
    width: 100% !important;
    margin: auto;
    display: inline-block;
    padding-right: 15px;
  }
  .about-wrap.style2 .about-img-wrap .about-bg-4 {
    display: none;
  }
}
</style>
